@font-face {
    font-family: 'SuisseIntlCond-Regular-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'SuisseIntlCond-Light-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
  }

.Answer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
}

.top_answer_page{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.top_answer_page_wrapper{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.top_answer_page_wrapper button{
  font-family: 'SuisseIntlCond-Regular-WebS';
  font-weight: 700;
  font-size: 1.25rem;
  border-color: transparent;
  width: 5rem;
  cursor: pointer;
  
}

.top_answer_page button:hover {
  transform: scale(1.02);
  border-bottom: 2px solid black;
}

button.button-selected {
  border-bottom: 2px solid black;
}

.Forms_title{
  width: 40%;
   
  text-align: center;
   font-family: 'SuisseIntlCond-Regular-WebS';
   font-weight: 700;
   font-size: 1.3rem;

   border-radius: 0.375rem;
   border: 1.5px solid #1690C3;
   background: #F2F2F2;

   color: #1690C3;
}

.Forms_title_answer{
   width: 40%;
   
   text-align: center;
    font-family: 'SuisseIntlCond-Regular-WebS';
    font-weight: 700;
    font-size: 1.3rem;

   
    color: #1690C3;
}

.Summary_answer_page{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    margin-top: 5%;
    margin-bottom: 10%;
    color: #5A5A5A;

}

.construction-site{
    width: 100%;
    font-size: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    color: #5A5A5A;
}

.individual_answer_page{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.no-responses{
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    color: #5A5A5A;
}

.button_answer_page{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
    margin-bottom: 1.2rem;
    
}

.button_answer_page button{
   
    border-radius: 0.375rem;
    border:transparent;
    background: #F2F2F2;
    color: #1690C3;
    font-size: 1.2rem;
    cursor: pointer;
}

.button_title{
    font-size: 1.2rem;
}



.resposta-item{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.375rem;
    border: 1.5px solid #1690C3;
    background: #F2F2F2;

    gap: 2rem;

    margin-bottom: 1.2rem;
}

.individual_pergunta{
    width: 20%;
    margin-top: 5%;
    
    text-align: center;
}

.individual_resposta{
    width: 50%;
    margin: 2% 5% 5% 5%;
    text-align: center;

    border-radius: 0.375rem;
    border: 1.5px solid #1690C3;
    padding: 0.5rem;
    background: #F2F2F2;

    color: #5A5A5A;
    font-weight: 100;
}

.individual_resposta_imagem{
    width: 50%;
    margin: 2% 5% 5% 5%;
    text-align: center;

    border-radius: 0.375rem;
    border: 1.5px solid #1690C3;
    padding: 0.5rem;
    background: #F2F2F2;

    color: #5A5A5A;
    font-weight: 100;
}