.navcontainer{
    width: 100%;
    display: flex;
    justify-content: center;
}

.navbar2{
    width: 92%;
    height: 6.5rem;
    display: flex;
    justify-content: space-between;
    align-items: end;
 
}

.navbar2 img{
    width: 10.08919rem;
    height: 2.33125rem;
    cursor: pointer;
}

