.Template2{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}


.jekformsT2{
  width: 6rem;
  align-self: flex-start;
  margin: 2rem 0 0 2rem;
}

.bottomt2{
  display: flex;
  flex-direction:column;
  width: 40%;
  align-self: center;
  margin-top: 15%;
  gap: 2rem;
}

@media (max-width: 700px){

  .Template2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .jekformsT2{
    width: 3rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }

  .bottomt2{
    display: flex;
    flex-direction:column;
    width: 40%;
    align-self: center;
    
    
  }
 
  }