html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.jekformsTOP{
    width: 10.08919rem;
    height: 2.33125rem;
    align-self: flex-start;
    margin: 4.2rem 0 0 4.8rem;
  
  }

  @media (max-width: 700px){
    .jekformsTOP{
      width: 7rem;
      align-self: flex-start;
      margin: 2rem 0 0 2rem;
    }
  }

  .Button-containerop4 {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto auto 5rem auto;
    margin-bottom: 10%;
  }

  
.ButtonNext{
  width:5rem;
  transition: transform 2s ease-out;
  font-family: 'SuisseIntlCond-Regular-WebS';
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.3rem;
  color: #1690C3;
  font-weight: 500;

}

.ButtonNext:hover {
    transform: scale(1.02);
    border-bottom: 2px solid #1690C3;
  }

  .ButtonBack{
    width:5rem;
    transition: transform 2s ease-out;
    font-family: 'SuisseIntlCond-Regular-WebS';
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
    font-size: 1.3rem;
    color: #1690C3;
    font-weight: 500;
  
  }

  
.ButtonNext:hover {
  transform: scale(1.02);
  border-bottom: 2px solid #1690C3;
}


  
@font-face {
    font-family: 'SuisseIntlCond-Light-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'SuisseIntlCond-Regular-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
  }
  
  
/*--------------------------------------------template 1 ( imagem e titulo )--------------------------------------------*/

  .Template1OP1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    
  }

  .left1{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8rem;
  }

  .logoshowcontainer{
    width: 80%;
    height: 6.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    display: flex;
    flex-direction: row;
    align-items: end;
  }

  .right1{
    width: 50%;
    height: 100vh;
  }


  .contentOP1{
    font-family: 'SuisseIntlCond-Regular-WebS';
     color: var(--e_azul, #1690C3);
      width: fit-content;
      text-align: center;
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
  }

  .ContinuarBtnOP1{
   
    width: 27.875rem;
    height: 3rem;
    flex-shrink: 0;
    border-radius: 0.375rem;
    color: var(--white, #F2F2F2);
    background-color: #1690C3;
    border-color: transparent;
    font-family: 'SuisseIntlCond-Light-WebS';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: transform 2s ease-out;
  }

  .ContinuarBtnOP1:hover{
    transform: scale(1.02);
  }

  .imagenzinha{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
 @media (max-width: 700px){
    .Template1OP1{
      flex-direction: column;

    }
    .left1{
      width: 100%;
      height: 100%;
      gap: 2rem;
    }

    .contentOP1{
      width: 60%;
      font-size: 2rem;
    }

    .ContinuarBtnOP1{
      width: 100%;
      height: 3rem;
      font-size: 1.5rem;
    }

    .right1{
      width: 100%;
      height: calc(100vh - 20rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .imagenzinha{
      width: 60%;
      height: 80%;
      object-fit: contain;
    }

    
  }


/*--------------------------------------------template 2 ( titulo )--------------------------------------------*/

  .Template2OP{
    width: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }

.contentOP2{
     
     margin-top: 5%;
    font-family: 'SuisseIntlCond-Regular-WebS';
     color: var(--e_azul, #1690C3);
      width: fit-content;
      text-align: center;
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
  }


  .ContinuarBtnOP2{
    margin-top: -10%;
    width: 27.875rem;
    height: 3rem;
  
    border-radius: 0.375rem;
    color: var(--white, #F2F2F2);
    background-color: #1690C3;
    border-color: transparent;
    font-family: 'SuisseIntlCond-Light-WebS';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: transform 2s ease-out;
  }

  .ContinuarBtnOP2:hover{
    transform: scale(1.02);
  }

  @media (max-width: 700px){
    .Template2OP{
      flex-direction: column;
      height: 100vh;
      gap: 10rem;
    }

    .contentOP2{
      
      width: 60%;
      font-size: 2rem;
    }

    .ContinuarBtnOP2{
      width: 100%;
      height: 3rem;
      font-size: 1.5rem;
    }
  }

 /*--------------------------------------------template 3 ( pergunta )--------------------------------------------*/

  .Templateop3{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    
    background-image: url("../../assets/img/BackgroundT3.jpg");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
 }

 .left3{

   width:100%;
  height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 4rem;
 }



.contentOP3{

  font-family: 'SuisseIntlCond-Light-WebS';
  color: var(--e_azul, #1690C3);
   width: fit-content;
   text-align: center;
   font-size: 1.5rem;
   font-style: normal;
   font-weight: 700;
   line-height: normal;
   z-index: 1;

   display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
   
}

.pergunta-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}




.caixacheck{
  
  width: 27.875rem;
  height: 3rem;

  border-radius: 0.375rem;
  border-color: #1690C3;

  color:#1690C3 ;
  background-color: #F2F2F2;

  font-family: 'SuisseIntlCond-Light-WebS';
  text-align: start;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  cursor: pointer;
  transition: transform 2s ease-out;

  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.caixacheck{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.caixacheck:hover{
  transform: scale(1.02);
}

@media (max-width: 700px){
  .Templateop3{
    flex-direction: column;
    height: 100vh;
    gap: 10rem;

    background-image: url("../../assets/img/Mobile-Background.jpg");
  }

  .left3{
    width: 100%;
    height: 100%;
    gap: 2rem;
  }

  .caixa-size{
    width: 100%;
    height: 3rem;
    font-size: 1.5rem;
  }

  .contentOP3{
    width: 60%;
    font-size: 1rem;
    margin-top: 20%;
  }

  .caixacheck{
    width: 100%;
    height: 2.5rem;
    font-size: 1.5rem;
    padding: 0.5rem 0.5rem;
  }
}

/*--------------------------------------------template 4 ( input )--------------------------------------------*/

.Templateop4{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction:column;
  align-items: center;

  background-image: url("../../assets/img/T4CBACK.svg");
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  
}



.middlet4{
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}


.contentOP4{
 
  text-align: center;
  width: fit-content;
  

  font-family: 'SuisseIntlCond-Light-WebS';
  
  display: flex;
  flex-direction: column;
  align-items: center;
}



.inputop4{
  
 
  width: 200%;
  border-color: transparent;
  background-color: transparent;
  border-bottom-color: #1690C3;
  font-size: 1rem;
  color: #1690C3;
  font-family: 'SuisseIntlCond-Light-WebS';
  text-align: center;
  z-index: 2;
}

@media(max-width: 700px){
  .Templateop4{
    height: 100vh;
    background-image: url("../../assets/img/Background-mobile2.png");

    background-position: left bottom;
    
  }

  .middlet4{
    height: 100%;
    gap: 1rem;
  }

  .inputop4{
    width: 100%;
    height: 3rem;
    font-size: 1.5rem;
  }
}



/*--------------------------------------------template 5 ( calendario )--------------------------------------------*/

.Template5op{

  height: 100vh;
  display: flex;
  flex-direction:column;
  gap: 2rem;
  

}

.contentOP5{

    font-family: 'SuisseIntlCond-Light-WebS';
    color: var(--e_azul, #1690C3);
    text-align: center;
 
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}



.titulotop5{
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.quadradoWrapperOP5{
  
width: 27.5rem;
height: 31rem;
max-width: 100%;
background: transparent;
border: 0.15rem solid #1690C3; /* Cor da borda */
border-radius: 0.5em;
font-family: 'SuisseIntlCond-Regular-WebS';
line-height: 1.125em;
}


.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}


.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 4rem; 
  width: 27.5rem; 
  margin-bottom: 2vh;
  border-bottom: 0.2vw solid #1690C3;;
  border-radius: 0.5em;
}

.react-calendar__navigation button  {
  width: 3rem; 
  background: none;
  font-size: 130%; 
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: 400; 
  color: #1690C3;
  
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 5%;
}

.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none;
}

.react-calendar__tile{
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: bold;
  width: 1.375rem;
  height: 1.9375rem;
  background-color: transparent;
  padding: 1rem;
}

.react-calendar__month-view__days__day{
  height: fit-content;
  font-size: 1.3rem;
  
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: transparent;
}

.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button{
display: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
   
}
.react-calendar__tile--now {
  color: #76baff;; 
}

.react-calendar__tile--hasActive {
  background: #76baff; /* Cor dos dias com atividades */
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff; 
}
.react-calendar__tile--active {
  
  box-sizing:10px;
  border-radius: 4rem;
  background: #1690C3;
  color: #F2F2F2;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {

  border-radius: 4rem;
  background: #1690C3;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6; /* Cor de fundo ao passar o mouse em seleção de faixa */
}

@media (max-width: 700px){
  .react-calendar__viewContainer{
    background-color: #F2F2F2; 
  }

  .Template5op{
    height: 100vh;
    gap: 1rem;

    background-image: url("../../assets/img/Mobile-Background.jpg");
    background-position: right bottom;
    background-repeat: no-repeat;
  
  }

  .quadradoWrapperOP5{
    background-color: #F2F2F2; 
    width: 20rem;
    height: 25.7rem;
    max-width: 100%;
    background: transparent;
    border: 0.15rem solid #1690C3; /* Cor da borda */
    border-radius: 0.5em;
    font-family: 'SuisseIntlCond-Regular-WebS';
    line-height: 1.125em;
    }
    
    
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}


.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 4rem; 
  width: 20rem; 
  margin-bottom: 2vh;
  border-bottom: 0.2vw solid #1690C3;;
  border-radius: 0.5em;
}

.react-calendar__navigation button  {
  width: 3rem; 
  background: none;
  font-size: 130%; 
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: 400; 
  color: #1690C3;
  
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 5%;
}

.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none;
}

.react-calendar__tile{
  font-family: 'SuisseIntlCond-Light-WebS';
  font-weight: bold;
  width: 1.375rem;
  height: 1.9375rem;
  background-color: transparent;
  padding: 1rem;
}

.react-calendar__month-view__days__day{
  height: fit-content;
  font-size: 1.3rem;
  
}

}

@media (max-width: 370px){
  .Template5op{
    height: 100vh;
    gap: 1rem;
  }

  .quadradoWrapperOP5{
    width: 17rem;
    height: 25.5rem;
    max-width: 100%;
    background: transparent;
    border: 0.15rem solid #1690C3; /* Cor da borda */
    border-radius: 0.5em;
    font-family: 'SuisseIntlCond-Regular-WebS';
    line-height: 1.125em;
    }

    
.react-calendar__navigation {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 4rem; 
  width: 17rem; 
  margin-bottom: 2vh;
  border-bottom: 0.2vw solid #1690C3;;
  border-radius: 0.5em;
}
 

  }

/*--------------------------------------------template 6 ( imagens )--------------------------------------------*/

.Templateop6{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction:column;
  align-items: center;
  gap: 1rem;
}

.top6{
  width: 100%;
  height: 90%;
  
  
}

.contentT6{
    width: 100%;
    text-align: center;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10%;
  }
  
  .titulotop6{
    margin-bottom: 4%;
    font-family: 'SuisseIntlCond-Light-WebS';
    font-weight: 400;
  }
  
  .imagensWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 1.2rem;
  }
  
  .imagensWrapper img{
    border-radius:0.375rem;;
    width: 20.25rem;
    height: 24rem;
    transition: transform 2s ease-out;
    cursor: pointer;
    
    
  }
  
 
  .imagens-selecionada{
    border: 2px solid #1690C3;
  
  }
  
  @media (max-width: 700px){

   
    .top6{
      width: 100%;
      height: 100%;
      gap: 1rem;
    }
  
    .contentT6{
      width: 100%;
      text-align: center;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-bottom: 10%;
    }
    
    .titulotop6{
      margin-top: 20%;
      margin-bottom: 4%;
      font-family: 'SuisseIntlCond-Light-WebS';
      font-weight: 400;
    }
    
    .imagensWrapper{

      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      gap: 1.2rem;
    }
    
    .imagensWrapper img{
      border-radius:0.375rem;;
      width: 10rem;
      height: 11.5rem;
      transition: transform 2s ease-out;
      cursor: pointer;
      
      
    }
    
   
    .imagens-selecionada{
      border: 2px solid #1690C3;
    
    }
  }

  @media (max-width: 528px){
  
    
    .titulotop6{
      margin-top: 5%;
      margin-bottom: 4%;
      font-family: 'SuisseIntlCond-Light-WebS';
      font-weight: 400;
    }
    
    .imagensWrapper{

      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      gap: 1.2rem;
    }
    
    .imagensWrapper img{
      border-radius:0.375rem;;
      width: 16rem;
      height: 14.5rem;
      transition: transform 2s ease-out;
      cursor: pointer;
      
      
    }
    
   
    .imagens-selecionada{
      border: 2px solid #1690C3;
    
    }
  }
 
/*--------------------------------------------template 7 ( download )--------------------------------------------*/

.Template7op{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction:column;
  align-items: center;
  gap: 1rem;
}

.contentOP7{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'SuisseIntlCond-Light-WebS';
    margin-top: 4%;
  }
  
  .titulotop7{
    margin-bottom: 3%;
  }
  
  .quadradoWrapperOP7{
    width: 42.875rem;
    height: 12rem;
    border-radius: 0.375rem;
    border: 2px solid #1690C3;


    color: #5a5a5a;
    background-color: #F2F2F2;
    font-family: 'SuisseIntlCond-Light-WebS';
    text-align: start;
    font-size: 6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: transform 2s ease-out;

   
  }

  .labelinput{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .DownBOP7{
    transition: transform 2s ease-out;
    cursor: pointer;
  }
  
  .DownBOP7:hover{
    transform: scale(1.02);
  }

  @media (max-width: 700px){

    .contentOP7{

      margin-top: 30%;
    }
    .quadradoWrapperOP7{
      
      width:25rem;
      height: 8rem;
      font-size: 4rem;
      
     
    }
  }

  @media (max-width: 430px){

    .contentOP7{

      margin-top: 50%;
    }
    .quadradoWrapperOP7{
      
      width:16rem;
      height: 7rem;
      font-size: 3rem;
      
     
    }
  }
 
/*-------------------------------------------------- avaliação ---------------------------------------------*/


.avalpage{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10rem;
}

.avalg{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avaltitle{
  text-align: center;
  color: #1690C3;
  font-family: 'SuisseIntlCond-Light-WebS';
  font-size: 1.5rem;
  
}

.avalcontent{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.avalrating.MuiRating-root{
  color: #1690C3;
  margin-bottom: 5%;
  font-size: 4rem;
  

}

