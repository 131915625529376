@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}



.signIn {
  height: calc(100vh - 20rem);
  font-family: 'SuisseIntlCond-Light-WebS';
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titlelogin {
  color: #1690C3;
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  margin: 0;
  margin-bottom: 3rem;
  
}

.google-btn {
  
  background-color: #1690C3;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 2s ease-out;
}

.google-btn:hover {
  background-color: #7CCCED; 
  transform: translateY(-5px); 
}
