
@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}



.topcreatepage{
  height: 44rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.top-name{

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Nomeform{
 
  font: 1em 'SuisseIntlCond-Regular-WebS';
  background-color: #F2F2F2;
  width: 100%;
  padding: 0.5rem 0.5rem;
  height: 2rem;
  border-color: #1690C3;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}

.Nomeform::placeholder{
  color: #1690C3;
  font: 1em 'SuisseIntlCond-Regular-WebS';
  padding-left: 0.5rem;
}

.nomebtn{
  font: 1em 'SuisseIntlCond-Regular-WebS';
  color: #F2F2F2;
  background-color: #1690C3;
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
  cursor: pointer;
}

.nomebtn:hover{
  transition: transform 2s ease-out;
}

.formtype{
  width: 15%;
  font: 1em 'SuisseIntlCond-Regular-WebS';
  color: #1690C3;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.topcafter{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.add-T{
  width: 40rem;
  height:32rem;
  font: 1em 'SuisseIntlCond-Regular-WebS';
  color: #F2F2F2;
  display:flex;
  flex-direction: row;
  align-items: center;
  
}

.add-page-button{
  cursor: pointer;
}

.template-example{
  width: 100%;
  height: 90%;
  background-color: transparent;
  color: #fff;
  border: 2.5px solid #1690C3;
  border-radius: 0.375rem;
  font-size: 16px;
  z-index: 2;
}



.button-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  
}

.Modelobtn-,
  .Geralbtn-{
    font-family: 'SuisseIntlCond-Regular-WebS';
    font-weight: 1000;
    font-size: 100%;
    background: transparent;
    border-color: transparent;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  
  .Modelobtn-select,
  .Geralbtn-select {
    font-family: 'SuisseIntlCond-Regular-WebS';
    font-weight: 1000;
    text-decoration: underline 0.10rem;
    font-size: 100%;
    cursor: pointer;
    border-radius: 0.375rem;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    border-color:  transparent;
    background:  rgba(124, 204, 237, 0.3);
    padding: 0.5rem 1rem;
    flex-shrink: 0;
    
  }

  .bottomcreatepage{
    width: 100%;
    height: 17.5rem;
    background: rgba(124, 204, 237, 0.3);
 
  }

  .staydown{
    width: 100%;
    height: calc(100vh - 38.5rem);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .image-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    overflow-y: hidden;
    
    
  }
  
  .image-container img{
    margin: 2.12rem 2.12rem 2.12rem 2.12rem;
    width: 20rem;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
  }

  .image-container img:hover{
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;
  }


  
  .image-container::-webkit-scrollbar {
    width: 1rem;
    height: 0.5rem;
  }
  
  .image-container::-webkit-scrollbar-thumb {
    background-color:#1690C3 ;
    border-radius: 6rem; 
    width: 0.5rem;
  }

  

  @media (max-height: 940px){
    .staydown{
      height: auto;
     
    }
  }
  
  @media(max-width: 700px){
    .topcafter{
      flex-direction: column;
      justify-content: flex-end;
      gap: 1rem;
      height: calc(100vh - 24.05rem);
    }

    .add-T{  
      width: 18.1875rem;
      height: 13.0625rem;
      
    }

    .add-page-button{
      margin-bottom: 15%;
    }

    .button-container{
      width: 100%;
    }
    .Modelobtn-, .Geralbtn-{
      width: 50%;
    }

    .Modelobtn-select, .Geralbtn-select{
      width: 50%;
    }

    .staydown{
      height: auto;
     
    }

    
  .image-container img{
      margin: 2rem 2rem 2rem 2rem;
      width: 15rem;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
      transition: transform 0.5s ease-in-out;
  }

  .bottomcreatepage{
    height: 15rem;
  }
  }