.Template4{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/T4CBACK.svg");
    background-size: cover;

    display: flex;
    flex-direction: column;
}

.jekformsT4{
  width: 6rem;
  align-self: flex-start;
  margin: 2rem 0 0 2rem;
}

.contentT4{
  width: 100%;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 700px){
  .Template4{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/T4CBACK.svg");
    background-size: cover;
  
    display: flex;
    flex-direction: column;
  }
  
  .jekformsT4{
    width: 3rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }

  
}