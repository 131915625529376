.Template6{
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.jekformsT6{
  width: 6rem;
  align-self: flex-start;
  margin: 2rem 0 0 2rem;
}

.contentTt6{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}



.quadradoWrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
}

.QA1{
  cursor: pointer;
}

.QA1 img {
  width: 13rem;
  height: 13rem;
}

.QA2 img {
  width: 13rem;
  height: 13rem;
}

.QA3 img {
  width: 13rem;
  height: 13rem;
}

@media (max-width: 700px){
  .Template6{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
    
  }
  
  .jekformsT6{
    width: 3rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }

  .contentTt6{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    

  }

  .quadradoWrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    
  }

  .QA1 img {
    width: 5rem;
    height: 5rem;
  }

  .QA2 img {
    width: 5rem;
    height: 5rem;
  }

  .QA3 img {
    width: 5rem;
    height: 5rem;
  }
}