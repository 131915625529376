.Template3{
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/T3CBACK.svg");
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.jekformsT3{
  width: 6rem;
  align-self: flex-start;
  margin: 2rem 0 0 2rem;
}

.contentT3{
  width: 100%;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.inputContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Addboxout{
  display: none;
}

@media (max-width: 700px){
  .Template3{
    width: 100%;
    height: 100%;
    background-image: url("../../assets/img/T3CBACK.svg");
    background-repeat: no-repeat;
    background-size: cover;
  
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .jekformsT3{
    width: 3rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }
  
  .contentT3{
    margin-top: 0%;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    overflow: scroll;
  }
  .templateTitleT3{
    width: 100%;
  }
  
  .inputContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  } 