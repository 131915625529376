
@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}


.container_Menu{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.top{
  width: 100%;
}


.middle{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.middle1{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search-container{
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  margin-left: 30%;
  
}

.lupa{
  font-size: 1rem;
  position: relative;
  left: 0.5rem;
  color: #1690C3;
}

.pesquisa{
  margin-left: -1rem;
  font: 1em 'SuisseIntlCond-Regular-WebS';
  background-color: #F2F2F2;
  width: 23.5%;
  height: 0.6rem;
  padding: 1rem 35px;
  border-radius: 6px;
  border: 1px solid #1690C3; /* Adicione "solid" para definir o estilo da borda */

}

.buttons-container{
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.createbtn {
  margin-right: 15%;
  height: 3rem;
  cursor: pointer;
  border-radius: 0.375rem;
  border-color: transparent;
  background: var(--c_azul, #7CCCED);
  transition: transform 0.2s ease-out;
}

.createbtn{
  font-family: 'SuisseIntlCond-Regular-WebS';
  color: #F2F2F2;
  font-size: 1.5rem;
  text-align: right;
  font-weight: 500;
}

.createbtn:hover{
  transform: scale(1.02);
}

.btn-wrapper{
  margin-left: 15%;
}

.Activebtn{
  font-family: 'SuisseIntlCond-Regular-WebS';
  font-weight: 900;
  font-size: 1.5rem;
  border-color: transparent;
  width: 5rem;
  cursor: pointer;
}

.Inactivebtn{
  font-family: 'SuisseIntlCond-Regular-WebS';
  font-weight: 900;
  font-size: 1.5rem;
  border-color: transparent;
  width: 5rem;
  cursor: pointer;
}

.Activebtn:hover {
  transform: scale(1.02);
  border-bottom: 2px solid black;
}

.Inactivebtn:hover {
  transform: scale(1.05);
  border-bottom: 2px solid black;
}


.bottom{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.column-layout {

  width:70%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}



@keyframes changeColor {
  0% {
    background: linear-gradient(to right, #7CCCED 5%,#72B2CC 10%,#1690C3 95%);
  }
  33% {
    background: linear-gradient(to right, #7CCCED 10%,#72B2CC 20%,#1690C3 80%);
  }
  66% {
    background: linear-gradient(to right, #7CCCED 20%,#72B2CC 30%,#1690C3 70%);
  }
  100% {
    background: linear-gradient(to right, #7CCCED,#72B2CC,#1690C3);
  }
}


.formulario-button-impar {
  background-color: #1690C3;
  color: white;
  padding: 2vw 2vh;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  width: 40.0%;
  height:1rem;
  margin-bottom: 1vh;
  font-family: 'SuisseIntlCond-Regular-WebS';
  display: flex;
  align-items: center;
  justify-content: right;
  pointer-events: auto; 
}

.formulario-button-par {
  background-color: #1690C3;
  color: white;
  padding: 2vw 2vh;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  width: 40%;
  height:1rem;
  margin-bottom: 1vh;
  font-family: 'SuisseIntlCond-Regular-WebS';
  display: flex;
  align-items: center;
  justify-content: left;
}


.formulario-button-impar{
  position: relative;
  margin-left: auto;
  margin-top: -6.5%;
  margin-bottom: 1.5%;
}



.formulario-button-impar:hover {
  
  cursor: pointer;
  animation: changeColor 0.1s ease 1 forwards;
  pointer-events: auto;
  
}

.formulario-button-par:hover {
  
  cursor: pointer;
  animation: changeColor 0.1s ease 1 forwards;
  
}

.formularios-container {
  display: flex;
  flex-direction: column;
  width: 83rem;
  margin-left: 12%;
}

.button-content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  width: 98%;
  height: 5rem;
  margin-left: -1%;
}


.ellipsis- {
  display: flex;
  align-items: center;
  height: 3.5rem;
  
  margin-left: auto;
  font-weight: 600;
  z-index: 2;
  transition: transform 0.2s ease-out;
  
}

.ellipsis-:hover{
  transform: scale(1.4);
}

.ellipsis-inactive{
  display: none;
}

.ellipsis-hidden{
  display: none;
}

.dropdown-content{
  
  display: flex;
  justify-content: space-evenly;
  width:100%;
  
  
}

#ic1
{

  transition: transform 0.2s ease-out;
}

#ic1:hover{
    transform: scale(1.1);
}


#ic2
{
  
  transition: transform 0.2s ease-out;
}

#ic2:hover{
    transform: scale(1.1);
}

#ic3
{

  transition: transform 0.2s ease-out;
}

#ic3:hover{
    transform: scale(1.1);
}

@media (min-width: 769px) 
  {
    .createbtn2{
      display: none;
    }
  }

@media (max-width: 768px) 
  {
    .createbtn{
      display: none;
    }

    .bottom{
      display: flex;
      flex-direction: column;
      gap: 2rem;
      justify-content: center;
      align-items: center;
    }
    
    .createbtn2{
      
      height: 3rem;
      cursor: pointer;
      border-radius: 0.375rem;
      border-color: transparent;
      background: var(--c_azul, #7CCCED);
      transition: transform 0.2s ease-out;
    }

    .createbtn2{
      font-family: 'SuisseIntlCond-Regular-WebS';
      color: #F2F2F2;
      font-size: 1.5rem;
      text-align: right;
      font-weight: 500;
    }
  
    
    .column-layout{
      display: flex;
      flex-direction: column;
      width: 70%;
      
    }

    .formulario-button-impar {
      width: 100%;
      height:3rem;
      font-family: 'SuisseIntlCond-Regular-WebS';
      display: flex;
      align-items: center;
      justify-content: left;
      pointer-events: auto; 
      margin-bottom: 3%;
     margin-left: 0%;
    }

    .formulario-button-par{
      width: 100%;
      height:3rem;
      font-family: 'SuisseIntlCond-Regular-WebS';
      display: flex;
      align-items: center;
      justify-content: left;
      pointer-events: auto; 
      margin-bottom: 10%;
    }

    .search-container{
      width: 70%;
      
      display: flex;
      align-items: center;
      margin-left: 0%;
      
    }

    .pesquisa{
      width: 100%;
    }
    
  }
