.Template5{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.jekformsT5{
  width: 6rem;
  align-self: flex-start;
  margin: 2rem 0 0 2rem;
}

.contentT5{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.contentT5 img{
  width: 40%;
}

@media (max-width: 700px){
  .Template5{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.4rem;
    
  }
  
  .jekformsT5{
    width: 3rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }

  .contentT5{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    

  }

  .contentT5 img{
    width: 40%;
  }
}