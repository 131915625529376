.Template7{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
}

.jekformsT7{
  width: 6rem;
  align-self: flex-start;
  margin: 2rem 0 0 2rem;
}

.contentTt7{
  margin-top: 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  
}

.contentTt7 img{
  width: 80%;
  height: 100%;
}

@media (max-width: 700px){
  .Template7{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .jekformsT7{
    width: 3rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }

  .contentTt7{
    margin-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .contentTt7 img{
    width: 80%;
    height: 100%;
  }
}