.Template1{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}


.leftside{
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


}



.jekformsT1{
  width: 6rem;
  align-self: flex-start;
  margin: 2rem 0 0 2rem;
}

.content{
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.content img{
      width: 50%;
}

.rigthside{
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D9D9D9;
}

.imageinput{
  font-size: 8rem;
  font-weight: 900;
  border-radius: 50%;
  color:#1690C3;
  transition: transform 2s ease-out;
}

@media (max-width: 700px){

  .leftside{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  
  }

  .rigthside{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
  }

  .jekformsT1{
    width: 3rem;
    align-self: flex-start;
    margin: 1rem 0 0 1rem;
  }

  .imageinput{
    font-size: 6rem;
    font-weight: 900;
    border-radius: 50%;
    color:#1690C3;
    transition: transform 2s ease-out;
  }
  

  .templateInput{
    width: 50%;
  }
}
