.navbar{
    width: 100%;
}

.navbar-container {
    width: 92%;
    height: 6.5rem;
    margin: 0 auto;
    display: flex;
    align-items: end;
}

.navbar-container img{
    width: 10.08919rem;
   height: 2.33125rem;
}
