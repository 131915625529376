.Inipage{
  width: 100%;
  background-image: url('../../assets/img/Entrada.jpg');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

@font-face {
  font-family: 'SuisseIntlCond-Regular-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
}

@font-face {
  font-family: 'SuisseIntlCond-Light-WebS';
  src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
}

.contentIni{
  height: calc(100vh - 7rem);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
 
}

.text-container{
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 85%;
  margin: 0 auto;
  align-self: flex-start;
}

.title {
  font-family: 'SuisseIntlCond-Regular-WebS';
  font-size: 4.5rem;
  font-weight: 600;
  margin: 0;
  
}

.texto {
  font-family: 'SuisseIntlCond-Light-WebS';  
  font-size: 4rem;
  margin: 0;
}

.Button-containerini {
  align-self: ;

  
  z-index: 2;
 
}

.Button {
  font-family: 'SuisseIntlCond-Regular-WebS';
  font-weight: 500;
  font-size: 1.5rem;
  border-color: transparent;
  color: #1690C3;
  width: 100%;
  height: 100%;
  transition: transform 2s ease-out;
  cursor: pointer;
  background-color: transparent;
}

.blue-text {
  color: #1690C3;
}

@media (max-width: 1480px) {
  .title {
    font-size: 4rem;
  }

  .texto {
    font-size: 3.5rem;
  }

  .Button {
    font-size: 1.4rem;
  }
}


@media (max-width: 1380px) {
  .title {
    font-size: 3.5rem;
  }

  .texto {
    font-size: 3rem;
  }

  .Button {
    font-size: 1.3rem;
  }
}


@media (max-width: 1260px) {
  .title {
    font-size: 3rem;
  }

  .texto {
    font-size: 2.5rem;
  }
  .Button {
    font-size: 1.2rem;
  }
}

@media (max-width: 1150px) {
  .title {
    font-size: 2.5rem;
  }

  .texto {
    font-size: 2rem;
  }
  .Button {
    font-size: 1.1rem;
  }
}

@media (max-width: 430px) {
  .contentIni{
    gap: 10rem;

    background-image: url('../../assets/img/Mobile-Background.jpg');
    background-position: right bottom;
    background-repeat: no-repeat;

  }

  .title {
    font-size: 2rem;
  }

  .text-container{
    gap: 1.5rem;
  }

  .texto {
    font-size: 1.5rem;
  }

  .Button-containerIni{
    
  }
}