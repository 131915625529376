@font-face {
    font-family: 'SuisseIntlCond-Regular-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Regular-WebS.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'SuisseIntlCond-Light-WebS';
    src: url('../../assets/fonts/SuisseIntlCond-Light-WebS.ttf') format('truetype');
  }
  
  .logout{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  
  }

.logout-button{
    background-color: transparent;
    border-color: transparent;
    width: 5rem;
    height: 2rem;
    font-family: 'SuisseIntlCond-Light-WebS';
    font-weight: 900;
    font-size: 1.2rem;
    color:#1690C3;
    cursor: pointer;
}

.logout-button:hover{
    transition: transform 2s ease-out;
}